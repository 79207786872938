.btn{
  &,&:link{
    padding:2rem 4rem ;
    text-decoration: none;
    border-radius:  300rem;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    transition: all .35s cubic-bezier(0.23,0.13,.67,.96);
    border: none;
  }
  &-purple{
    background-color: #AEB6FF;
    color: #181b1f;
    &:hover{
      background-color: #838cfa;
    }
  }
  &-red{
    background-color: #ffaec1;
    color: #181b1f;
    &:hover{
      background-color: #e5383c;
    }
  }
  &-grad{
    background:linear-gradient(65.97deg, rgba(47, 197, 244, 0.9) -4.12%, rgba(92, 114, 251, 0.719813) 43.39%, rgba(117, 68, 255, 0.621) 72.98%);
    color: #f7f7f7;
    &:hover{
      background-position: 100px;
      background-image: linear-gradient(-65.97deg, rgba(47, 197, 244, 0.9) -4.12%, rgba(92, 114, 251, 0.719813) 43.39%, rgba(117, 68, 255, 0.621) 72.98%),;
    }
  }
}
.btn-outlined{
  &,&:link{
    display: inline-block;
    padding:2rem 4rem ;
    text-decoration: none;
    border-radius:  30rem;
    border: 1px solid currentColor;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    position: relative;
    transition: all .35s cubic-bezier(0.23,0.13,.67,.96);
  }
  &-purple{
    color: #AEB6FF;
    background-color: transparent;
    transition: all .35s cubic-bezier(0.23,0.13,.67,.96);
    background-image: linear-gradient(67deg, #1a0823, rgba(6, 8, 42, 0.1));
    background-position-x: -200%;
    background-repeat: no-repeat;
    &:hover{
      color: #8F00FF;
      background-image: linear-gradient(67deg, #8F00FF, rgba(6, 8, 42, 0.1));
      background-position-x: 150px;
      background-repeat: no-repeat;
    }
  }
}
