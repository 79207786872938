.contact{
  border-radius: 3rem;
  padding: 4rem;
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.644) 52.51%);
  width: 40%;
  margin: 8rem auto;
  backdrop-filter: blur(10px);
  @include respond(tab-port){
    width: 70%;
  }
  &-area{
    display: flex;
    align-items: center;
    margin: 2rem 0;
    .la-envelope,.la-phone{
      font-size: 4rem;
    }
    span{
      font-size: 1.875rem;
      color: #f7f7f7;
    }
  }
}