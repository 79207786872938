.my-stack{
  //padding:5rem 10rem ;
  &-carousel{
    padding: 2rem 0;
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
  }
}
.cv-section{
  margin: 0 auto;
  width: 90%;
  padding: 5rem;
  @include respond(phone){
    width: 100%;
    padding: 1.5rem;
  }
}
.projects-area{
  margin: 0 auto;
  width: 90%;
  padding: 5rem;
  //background-color: white;
  @include respond(phone){
    width: 100%;
    padding: 1.5rem;
  }
}
.project-view{
  &-imgs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}


.stack-item{
  width: 25rem;
  height: 25rem;
  //display: block;
  border-radius: 1.5rem;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.644) 52.51%);
  @include respond(tabPort){
    margin: 1rem 1rem;
    width: 45%;
    

  }

}
.stack-item-2{
  width: 40rem;
  height: 20rem;
  background-color: $darkblue;
  @include respond(tabPort){
    margin: 1rem 0;
  }
}

.stack-item-3{
  width: 40rem;
  height: 40rem;
  background-color: $darkblue;
  @include respond(tabPort){
    margin: 1rem 0;
  }
}

.stack-img{
  width: 100%;
}
.stack-icon{
  font-size: 6rem;
  color: inherit;
  transition: all .35s ease;

  &:hover{
    transform: rotate(45deg);
  }
}
.nodejs{
  color: #179f1b;
  animation: circleout ease .5s;

}
.flutter{
  width: 5rem;
  fill: #1c7fff;
  color: #1c7fff;
  animation: circleout ease .5s;

}
.animatedCircle{
  position: relative;
  width: 10rem;
  height: 10rem;
  left: 25%;
  margin-bottom: 2rem;
  &,&::after{
    padding: 1.5rem;

    border-radius: 20rem;
    display: block;
    transition: all .8s ease ;
  }
  &::after{
    content: "";
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    border: 2px solid currentColor;
  }
  &:hover:hover::after,&:hover{
      opacity: 1;
    border: 2px solid currentColor;
  }
}
.react{
 color: #109cfa;
  animation: circleout ease .5s;

}
.php{
color: #245aff;
  animation: circleout ease .5s;
}
.sass{
  color: #ff247c;
  animation: circleout ease .5s;

}
.vue{
color: #22e59f;
  animation: circleout ease .5s;

}
.js{
  color: #e5ae22;
  animation: circleout ease .5s;

}
.twitter{
  color: #09a4f8;
  &:hover{
    color: #0e4394;
  }
}
.git{
  color: #ececec;
  &:hover{
    color: #c6c0c0;
  }
}
.linkedIn{
  color: #459bf6;
  &:hover{
    color: #1469e9;
  }
}
.stack-icon-sm{
  cursor: pointer;
  margin: 1rem 0;
  font-size: 2rem;
}

.project_img-box{
  width: 80%;
  @include respond(phone){
    width: 95%;
  }
  &-img{
    width: 100%;
    margin: 2.4rem 0;
    border-radius: 3rem;
  }
}


.about--section{
  position: relative;
  &-img{
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 80vh;
    border-radius: 1.5rem;
    background-position: top;

  }
  &-circle{
    width: 10rem;
    height: 10rem;
    position: absolute;

    border-radius: 300rem;
    background-color: #8F00FF;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s cubic-bezier(.12,.26,.66,.93);
    &-bottom-left{
      bottom: 5%;
      left: 0;
    }
    &-bottom-right{
      bottom: 5%;
      right: 0;
    }
    &-top-right{
      top: 5%;
      right: 0;
    }
    &:hover{
      transform: translateY(-5%) rotate(270deg);
    }
    &-inner{
      padding: 1rem;
      border: 2px dashed #AEB6FF;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 80%;
      border-radius: 200rem;
    }
  }
}
