.project-carousel{
  position: relative;
  &-img{
    width: 50rem;
    border: 2px solid #22e59f;
    border-radius: 2rem;
    padding: 1.5rem;
      margin: 2rem 2rem;
    transition: all .35s ease-in-out;

  }
  &-img-absolute{
    width: 100%;
  }
}

.disp_flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &:hover .project-carousel-img{
    border: 2px dot-dash #09a4f8;
  }
}
.project-carousel_container{
  position: relative;

}

.project{
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.9) 52.51%);
  padding: 2rem;
  border-radius: 3rem;
  max-width: 45%;
  overflow: hidden;
  text-decoration: none;

  @include respond(phone){
    background: linear-gradient(93.02deg, rgba(0, 0, 0, 1) 19.44%, rgba(0, 0, 0, 1) 52.51%);
    padding: 3rem 2rem;
  }

  &-img{
    width: 100%;
    border-radius: 3rem;
    transition: all .35s cubic-bezier(.13,.26,.67,.96);
    &:hover{
      transform: scale(1.1) translateY(-5%);
    }
  }
  &:hover{
    background-position: 200px;
  }
  @include respond(tabPort){
    max-width: 100%;
    padding: 1rem;
  }
  &-view{
    display: flex;
    justify-content: space-between;
  }
}

.project_scroll-h{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: scroll;
  @extend %scrollbars;
}
.project_alt{
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.9) 52.51%);
  padding: 2rem;
  border-radius: 3rem;
  max-width: 45%;
  overflow: hidden;
  &-img{
    width: 100%;
    border-radius: 3rem;
    transition: all .35s cubic-bezier(.13,.26,.67,.96);
    &:hover{
      transform: scale(1.1) translateY(-5%);
    }
  }
  &:hover{
    background-position: 200px;
  }
  @include respond(tabPort){
    max-width: 60%;
    padding: 1rem;
  }
  @include respond(phone){
    max-width: 90%;
    padding: 1rem;
  }
  &-view{
    display: flex;
    justify-content: space-between;
  }

  position: relative;
  &_bottom{
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    //backdrop-filter: blur(3px);
    background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.5) 52.51%);
    transition: all .35s ease-in-out;
  }
  &:hover > .project_alt_bottom{
    h2,p{
      transform: scale(1.1);
    }
    opacity: 1;
  }
}