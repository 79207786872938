
.darkCard{
  border-radius: 3rem;
  padding: 2.5rem;
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.19) 52.51%);
  @include respond(phone){
    padding: 2rem;
  }
}
.darkCard_{
  //border-radius: 3rem;
  padding: 8rem 2.5rem;
  background: rgba(0, 0, 0, 0.19);
  @include respond(phone){
    padding: 1rem;
  }
}
.testimonialCard{
  border-radius: 3rem;
  padding: 2rem;
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.644) 52.51%);
  position: relative;
  transition: all .35s cubic-bezier(0,0.2,0.6,.96);
  &:hover:after{
    transform: translateX(2%);
  }
  @include respond(tabPort){
    margin: 1.5rem 0;
  }
  &:after{
    width: 100%;
    height: 100%;
    border-width: 0 4px 4px 0;
    border-style: solid;
    border-color: #8F00FF;
    border-radius: 30px;
    transition: all .35s cubic-bezier(0,0.2,0.6,.96);
    content: "";
    position: absolute;
    bottom: -2.5rem;
    right: -2.5rem;
  }
}