.hero-H{
  font-size: 6rem;
}

h1,h2,h3,h4,h5,h6,p{
  color: var(--text);
}
em{
  color: #09a4f8;
  background-color: #0f111a;
  padding: .2rem;
}
.gold-em{
  color: #f8c527;
  background-color: #0f111a;
  padding: .2rem;
}
.hero-sub-H{
  font-size: 1.875rem;
}
.lg-h{
 font-size: 4.8rem;
}
.sm-p{
  font-size: 1.5rem;
}
.sm-h{
  font-size: 2rem;
  font-weight: normal;
}
.md-p{
  font-size: 3.2rem ;
}
.paragraph{
  font-size: 2rem;
  word-wrap: break-spaces;
  width: 70%;
  @include respond(phone){
    width: 100%;
  }
}
.cv-h{
  font-size:  2rem;
  margin: 1rem 0;
}
.cv-p{
 font-size:  2rem;
  color: #6b6868;
}


.major-h{
  font-size: 6.4rem;
}
.h1{
  font-size: 7.2rem;
}.h2{
  font-size: 4.8rem;
}.h3{
  font-size: 3.6rem;
}.h4{
  font-size: 2.4rem;
}.h5{
  font-size: 1.8rem;
}.h6{
  font-size: 1.6rem;
}.h7{
  font-size: 1.4rem;
}

 .purple{
   color:#AEB6FF
 }


.nowhitespace{
  white-space: nowrap;
}
.deepPurple{
  color: #8F00FF;
}

.greyText{
  color: #9f8fe6;
}
.whiteText{
  color: #f7f7f7;
}
.no-deco{
  text-decoration: none;
}

.text_just{
  text-align: justify;
}