.modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.1);
  width: 100%;
  height: 100%;
  z-index: 10000;
  backdrop-filter: blur(5px);
  overflow-y: scroll;
  @extend %scrollbars;
  &_top{
    &-close{
      cursor: pointer;
      font-size: 4.8rem;
      color: #1ea8f8;
      position: absolute;
      right:10rem;
      top: 10rem;
      z-index: 1200;
      @include respond(tabPort){
        right:2rem;
        top: 10rem;
      }
      &:hover{
        color: #1ea8f8;
      }
    }
  }
  &_body{
    //background-color: $darkBg;
    padding: 2rem;
    width: 40%;
    margin: 10rem auto;
    border-radius: 1.5rem;
    backdrop-filter: blur(2px);
    //border: 1px solid #f7f7f7;
    @include respond(tabPort){
      width: 60%;
    }
    @include respond(phone){
      width: 90%;
    }
  }
}
.empty-msg{

}

.zoom{
  transition: all .35s cubic-bezier(.89,.44,.32,.83);
  &:hover{
    transform: scale(1.8);
    transform-origin: top;
  }
}