@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,600&display=swap');*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins",Roboto,sans-serif;
  //color: #0f111a;
}
.f2{
  font-family: 'Raleway', sans-serif;
}
:root{
  --text: #f7f7f7;
  --bg-alt: #181d27;
}
html{
  font-size: 50%;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  @include respond(tabLand){
    font-size: 50%;
  }
  @include respond(tabPort){
    font-size: 56.25%;
  }
  @include respond(phone){
    font-size: 50%;
  }

  @include respond(bigScreen){
    font-size: 75%;
  }
}
body{
  position: relative;
  //background-color:#090c10;
  min-height: 100vh;
  background: linear-gradient(61.19deg, rgba(47, 197, 244, 0.112) 5.76%, rgba(18, 17, 45, 0.2) 63.11%), linear-gradient(219.89deg, #080635 18.86%, #000000 77.24%);

}
.dampen{
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.01) 52.51%);
}

%scrollbars{
  &::-webkit-scrollbar{
    width: 1em;

  }
  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
    padding: 5rem;
    border-radius: 2rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #303030;
    outline: none;
    border-radius: 2rem;
  }
  -ms-overflow-style: none;
  scrollbar-width:none;
}