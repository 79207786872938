.floating-icon{
  color: white;
  font-size: 3rem;
  &:hover{
     color: $primaryColor;
  }
}


.vertical-line{
  height: 14rem;
  width: .2rem;
  background-color: #8F00FF;
  @include respond(tabPort){
    height:10rem;
  }
  @include respond(phone){
    height:0;
  }
}


.icons-social{
  position: fixed;
  bottom: 10%;
  left: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond(phone){
    left: .5rem;
    bottom: 12%;
  }
  &-links{
    margin-top: 3rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:4rem 2rem;


  }
}


.overlay-contact{
  position: fixed;
  right: 2rem;
  display: flex;
  align-items: center;
  bottom: 10%;
  border-radius: 3rem;
  padding: 2rem;
  backdrop-filter: blur(10px);
  z-index: 1;
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.3) 52.51%);
  @include respond(tabPort){
    bottom: 2%;

  }
  @include respond(phone){

    padding: 1rem;
  }
  p{
    font-size: 2rem;
  }
}

.horizontal-line{
  margin-left: 4rem;
  width: 20rem;
  height: .2rem;
  background-color: #262626;
  @include respond(tabPort){
    width: 4rem;
  }

}

.mode{
  background-color: #3e3d3d;
  border-radius: 1rem;
  padding: .4rem 1.5rem;
  margin-left: 2rem;
  .la-sun{
    font-size: 3rem;
    color: #f7f7f7;
  }
}

