._mgT8{
  @include respond(tabPort){
    margin-top: 8rem;
  }
}
.overflowHidden{
  overflow-x: hidden;
}
.display_flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.hr-dashed{
  border-top: none;
  border-bottom: 2px dashed #8F00FF;
  margin: 2rem 0;

}
.mgV4{
  margin: 4rem 0;
}
.mgV6{
  margin: 6rem 0;
}
.mgV8{
  margin-top: 8rem!important;
  margin-bottom: 8rem!important;
}

.just-btw{
  justify-content: space-between;
}
.center-all{
  width: 80%;
  margin: 0 auto;
  @include respond(tabPort){
    width: 88%;
  }

}
.center-all_{
  width: 85%;
  margin: 0 auto;

}

.em{
  font-weight: bold;
}
._greyCard{
  background-color: #181b1f;
  padding: 1.5rem;
  display: inline-block;
}

.textCenter{
  text-align: center;
}
.greenText{
  color: #35bc25;
}
.text_left_mob{
  text-align: right;
  @include respond(tabPort){
    text-align: left;
  }
}
.mgT1{
  margin-top: 1rem;
}
.mgT8{
  margin-top: 8rem;
}
.mgT12{
  margin-top: 12rem;
}
.mgT4{
  margin-top: 4rem;
}
.mgT2{
  margin-top: 2rem;
}
.pdT8{
  padding-top: 8rem;
}

.pdV8{

  padding: 8rem 0;
}
.width80{
  width: 80%;
}
.mgL2{
  margin-left: 2rem;
}
.mgL2max{
  margin-left: 2rem;
  @include respond(tabPort){
    margin-left: 0;
  }
}
.fluidImg{
  width: 100%;
  border-radius: 1.5rem;
}
.img_box{
  padding: 2rem;
  background-color: var(--dark);

}
.vid_box{
  height: auto;
  background-color: var(--dark);

}
.edgeSqre{
  position: absolute;
  z-index: 3;
  bottom: -4rem;
  right: -4rem;
}
.shadowText{
  text-shadow: 0 4px 16px rgba(0,0,0,.25);
}
.border-card{
  padding: 1.5rem;
  border: 2px solid var(--dark);
  margin-right: 2rem;
}

.greyBox{
  background-color: #EAEAEA ;
  padding: 4rem;
  position: relative;
  min-height: 80vh;
  &:before{
    position: absolute;
    width: 10000px;
    transform: translate(-50%,-50%);
    height: 100vh;
    z-index: -1;

    background-color: #f7f7f7 ;
    content: "";
  }
  &:after{
    position: absolute;
    width: 10000px;
    transform: translate(-50%,0);
    height: 50vh;
    z-index: -1;
    bottom: 0;
    background-color: var(--dark-alt2);
    content: "";
  }
  @include respond(tabPort){
    padding: 2rem;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.mgB2{
  margin-bottom: 2rem;
}
.mgV2{
  margin: 2rem 0;
}
.mgB4{
  margin-bottom: 4rem;
}

.width60{
  width: 60%;
  @include respond(tabPort){
    width: 100%;
  }
}.width100{
   width: 100vw;
   @include respond(tabPort){
     width: 100%;
   }
 }

.width45{
  width: 45%;
  @include respond(tabPort){
    width: 90%;
  }
}
.width35{
  width: 35%;
  @include respond(tabPort){
    width: 90%;
  }
}
.width25{
  width: 25%;
  @include respond(tabPort){
    width: 90%;
  }
}
.width15{
  width: 15%;
  @include respond(tabPort){
    width: 100%;
  }
}

.pg{
  margin: 1rem;
  padding: 1rem;
  background-color: var(--dark);
  color: white;
  &:hover{
    color: var(--prim);
  }

  &-active{
    color: var(--prim);
  }
}

.greyCard{
  padding: 2rem;
  background-color: #f7f7f7;
}

.story_list{
  //padding: 1rem;
  list-style: none;
  display: inline-block;
  li{
    font-size: 1.6rem;
    &:not(:last-child){
      margin-bottom: 1rem;
    }
  }
}
.story__list{
  //padding: 1rem;
  list-style: none;
  display: inline-block;
  li{
    font-size: 1.6rem;
    padding: 1.25rem;
    background-color: black;
    color:#f7f7f7;
    font-weight: bold;
    display: inline-block;
    &:not(:last-child){
      margin-right: 1rem;
    }
    transition: all .35s ease-out;
    @include respond(tabPort){
      margin: 1rem;
    }
    &:hover{
      transform: translateY(-5%);
    }
  }
}

.position_sticky{
  position: sticky;
  top: 0;
}

.position_rel{
  position: relative;
}

.pd2{
  padding: 2rem;
}


.paginator{
  background-color: #202020;
  margin-top: 4rem;
  display: inline-block;
  padding: 1.5rem;
  border-radius: .2rem;
  color: white;
  ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    li{
      font-size: 1.8rem;
      cursor: pointer;
      margin: 0 1rem;
      &:hover{
        color: #37A050;
      }
    }
  }
}
.activeP {
  color: #37A050;
}
.inactiveP{
  color: #606060;
  cursor: text;
  &:hover{
    color:#606060!important;
  }
}
.loader-cont{
  position: fixed;

  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  background-color: rgba(0,0,0,.99);
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;


}
.circle-h{
  width: 20rem;
  height: 20rem;
  padding: 1rem;
  border: 4px solid #1469e9;
  border-radius: 200rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slow {

}
.help_circle{
  position: fixed;
  bottom: 10%;
  right: 4rem;
  z-index: 100;
  background-color: #C49437;
  padding: 1rem;
  border-radius: 200rem;
  box-shadow: 0 8px 20px rgba(0,0,0,.2);
  color: #f7f7f7;
  font-size: 3.6rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond(phone){
    bottom: 15%;
    right: 2rem;
  }
}

.hr{
  border: 4px solid #8F00FF;
  border-top: none;
  margin: 3rem 0;
}
.hr-sm{
  width: 80%;
  border: 1px solid #8F00FF;
  border-top: none;
  margin: 3rem 0;
  @include respond(tabPort){
    width: 100%;
  }
}

.just_end{
  justify-content: flex-end;
}


.auth_circle{
  width: 2rem;
  height: 2rem;
  border-radius: 200rem;
  background-color: #cccccc;
  display: inline-block;
  transition: all .35s  ease-in-out;
  &-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:not(:last-child){
    margin-right: 1.5rem;
  }
  &-active{
    width: 2rem;
    height: 2rem;
    border-radius: 200rem;
    display: inline-block;
    background-color: #262626;
    margin-right: 1.5rem;
  }
}

.minusBtn{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background-color: #f64d4d;
  font-size: 1.8rem;
  color: white;
  padding:.5rem 1.5rem;

}

.just-centre{
  justify-content: center;
}


.border-green-1{
  border: 1px solid var(--primary-lime);
}
.border-grey-2{
  transition: all .35s ease-in;
  border: 2px solid #C4C4C4;
}
.greenCardSm{
  color: #608c3e;

}

.fl-c-t{
  @include respond(tabPort){
    flex-direction: column;
  }
}

.test_flex{
  display: flex;
  @include respond(tabPort){
    flex-direction: column;
  }
}


.pdV2{
  padding: 2rem 0;
}

.fixed_bg{
  min-height: 80vh;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem;
  //height: 80vh;
  //background-color: #1469e9;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &-text{

  }
}