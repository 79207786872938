#menu{
  display: none;
}

#menu:checked ~ .top-nav-list{
  display: flex;
}


#menu:checked ~ .menu-box .menu{
  &{
    background-color: transparent;
  }
  &:after{
    transform: rotate(-45deg) translateY(-1.4rem);
    background-color: #f7f7f7;
  }

  &:before{
    transform: rotate(45deg) translateY(1.4rem);
    background-color: #f7f7f7;
  }
}

#menu ~ .top-nav-list{
  display: flex;
  @include respond(tabPort){
    display: none;
  }
}

.logo-link{
  text-decoration: none;
}


.menu-box{
  //background-color: $greyBg;
  border-radius: 1rem;
  padding: 2.2rem;
  position: fixed;
  cursor: pointer;
  display: none;
  z-index: 10;

  @include respond(tabPort){
    display: block;
    right: 1rem;
  }
  .menu{
    &,&:before,&:after{
      width: 3rem;
      height: .2rem;
      border-radius: 2rem;
      background-color: #AEB6FF;
      position: absolute;
      left: 0;
      transition: all .35s;

    }
    &:before,&:after{
      content: ' ';
    }
    &:before{
      top: -1rem;
    }

    &:after{
      top: 1rem;
    }
  }
}

.top-logo{
  height: 4.8rem;
}