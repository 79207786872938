.mg-v1{
  margin: 1rem 0;
}
.mgT4{
  margin-top: 4rem;
}.mgT1{
  margin-top: 1rem;
}
.mgR-1{
  margin-right: 1rem;
}
.mg-v2{
margin: 2rem 0;
}
.mg-v4{
  margin: 4rem 0;
}
.mg-b4{
  margin-bottom: 4rem;
}
.mg-h2{
  margin: 0 2rem;
}
.mg-l2{
  margin-left: 2rem;
}

.bld{
  font-weight: bold;
}
.pd-v2{
  padding: 2rem 0;
}
.h-40{
  min-height: 20rem;
}