.contact-form{
  background-color: rgba(0,0,0,.25);
  padding: 4rem;
  border-radius: 2rem;
  position: relative;
  &:after{
    position: absolute;
    content: "";
    height: 20%;
    width: .4rem;
    background-color: #8F00FF;
    left: 0;
    top: 25%;
  }
}



.form-input-box{
  padding: .8rem .5rem;

  background-color: #1B1D2E;
  border-radius: 1rem;
  margin: 1.6rem 0;
  color: #ffffff;

  .form-textArea{
    padding:1.2rem 1rem;
    border: none;
    background-color: inherit;
    width: 100%;
    height: 5rem;
    resize: none;
    color: inherit;
    &:focus{
      outline: none;
    }
  }

  .form-input{
    padding:0.2rem 1.2rem;
    border: none;
    background-color: inherit;
    width: 100%;
    color: inherit;
    &:focus{
      outline: none;
    }
  }

  .form-select{
    padding:1.2rem 1rem;
    border: none;
    background-color: inherit;
    width: 100%;
    color: inherit;
    &:focus{
      outline: none;
    }
  }

}

.form-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-input-box{
    flex: 1;
    &:not(:last-child){
      margin-right: 1.5rem;
    }
  }
}


.form-file{
  padding: 1rem;
  border-radius: 1.5rem;
  background-color:  #1B1D2E;
  border: 2px dashed #8F00FF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
  color: #f7f7f7;
  .form_img{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img{
      width: 100%;
      max-width: 20rem;
      margin: 1rem;
    }
  }
  .file-input{
    border: none;
    display: none;
  }
}

#file_type_input{
  display: none;
}
