.list{
   margin-left: 2rem;
   //list-style: none;
  padding: 1rem;
  li{
    padding: 1rem;
    font-size: 1.8rem;
    color: #09a4f8;
    a{
      font-size: 1.8rem;
      color: #09a4f8;
    }
  }

}
.grey-box{
  background-color: #0f111a;
  display: inline-block;
  padding: 1rem;
  width: 50%;
  margin-top: 1rem;
  @include respond(tab-port){
    width: 100%;
  }

}