@import "../helpers/mixins";
.tabs{
  &__top{

    padding: 1.5rem 0;
    z-index: 1;
    &-item{
      color: #4d4f7f;
      font-size: 1.8rem;
      position: relative;
      cursor: pointer;
      padding: 1.8rem;
      border: 2px solid currentColor;
      border-radius: 20rem;
      &:not(:first-child) {
        margin-left: 2rem;
      }
      &-active{
        color: #5C72FB;

        &:after{
          display: block!important;
        }
      }
      &:hover{
        color: #5C72FB;

        animation: jiggle .35s cubic-bezier(0.2,.43,.66,.93) alternate;
      }
      &:after{
        content: "";
        position: absolute;
        background-color: currentColor;
        background-image: linear-gradient(65.97deg, rgba(47, 197, 244, 0.9) -4.12%, rgba(92, 114, 251, 0.719813) 43.39%, rgba(117, 68, 255, 0.621) 72.98%);;
        width: 1rem;
        height: 1rem;
        bottom: -1.7rem;
        border-radius: 200rem;
        right: 0;
        display: none;
      }
      &:hover:after{
        display: block;
      }
    }
  }
  &__body{
    padding: 1.5rem;
    border-radius: 3rem;
    margin-top: 4rem;
    overflow-x: auto;
    background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.644) 52.51%);
    @include respond(phone){
      padding: 1rem;
      background: none;
    }
  }
}

@keyframes jiggle {

  0%{
    opacity: 0;
    transform: translateX(5%);
  }

  100%{
    opacity: 1;
    transform: translateX(-5%);
  }
}