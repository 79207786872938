.top-nav{
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 4rem 5rem;
  font-size: 1.6rem;
  //border-bottom: 1px solid #30363d;
  align-items: center;
  z-index: 200;
  width: 90%;
  margin: 0 auto;
  @include respond(phone){
    width: 100%;
    padding: 2rem 1rem;
  }
&-list{
  display: flex;
  justify-content: space-between;
  //flex: .4;
  list-style: none;
  transition: all .5s ease-out;
  background: linear-gradient(93.19deg, rgba(117, 68, 255, 0.047) 38%, rgba(117, 68, 255, 0.06) 52.64%), linear-gradient(90deg, rgba(18, 17, 45, 0.7) 25.96%, rgba(10, 13, 45, 0.7) 70.47%);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 2rem 2rem;
  border-radius: 200px;
  &--item{
    cursor: pointer;
    font-size: 1.8rem;
    transition: all .35s ease-in;
    margin: 0 1rem;
    &:hover &--link{
      color: $primaryColor;
    }
    &--link{
      z-index: 12;
      text-decoration: none;
      animation: .35s ease-out;
      color: #7081c3;
      transition: all .35s ease;
      font-weight: bold;
      font-size: 1.8rem;

    }
  }
  @include respond(tab-land){
    flex: .8;
  }
  @include respond(tabPort){


    flex-direction: column;
    position: fixed;
    justify-content: center;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    z-index: 1;
    animation: slideFromLeft  cubic-bezier(1,0,0,1);
    align-items: baseline;
    border-radius: 0;
    &--item{
      cursor: pointer;
      margin: 2rem 0;
      position: relative;
      padding: 2rem;
      &::before{
        content: " ";
        height: 1rem;
        width: 1rem;
        position: absolute;
        //background-color: #1469e9;
        //background-image: linear-gradient(45deg, rgba(255,255,255,.2), #0f111a);
        right: 1rem;
        top: 1rem;
        border-radius: 0;
        transform: scale(0);
        z-index: -1;
        transition: width cubic-bezier(.17,.67,.83,.67) .2s,
                    transform .2s,
        border-radius cubic-bezier(.17,.67,.83,.67);

      }
      &:hover:before{
          transform: scale(1);
        width: 100%;
        border-radius: 1.5rem;
      }
      &:hover &--link{
        font-weight: bold;
        color: #ece7e7;
        z-index: 25;
      }
      &--link{

        color: white;
        font-size: 2.4rem;
        text-decoration: none;
        animation: .35s ease-out;
      }
    }

  }

}
}

.hero-section{
  min-height: 70vh;
  padding: 10rem 0 4rem 0;
  background-position: bottom right;
}
@keyframes slideFromLeft {
  from{
    transform: translateX(-1000px);
  }
  to{
    transform: translateX(0);
  }
}


.circle_avatar{
  width:15rem;
  height: 15rem;
}
.testimonial-section{
  padding: 4rem 0;
}

.projectsOverview{
  background: linear-gradient(93.02deg, rgba(0, 0, 0, 0.7) 19.44%, rgba(0, 0, 0, 0.644) 52.51%);
  padding: 8rem 0;
  min-height: 25vh;
}

.avatar{
  border-radius: 200rem;
  transition: all .35s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  &:hover{
    //border: 4px solid #8F00FF;
    border-radius: 200rem;
  }
}

.hero-circle{
  width: 70%;
  margin: 0 auto;
  transition: all .35s ease-in;
  position: relative;
  svg{
    width: 100%;
    animation: rotate 3s infinite alternate cubic-bezier(.12,.24,.66,.93);
  }

  @include respond(tabPort){
    width: 80%;
    //transform: translate(-2rem,45%);
  }
  @include respond(phone){
    width: 110%;
    transform: translate(-2rem,45%);
  }
}

@keyframes rotate {
  100%{
    transform: rotate(360deg);
  }
}